import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFound from '../views/passPage/NotFound.vue'
import PrivacyPolicy from '../views/passPage/PrivacyPolicy.vue'
import UserAgreement from '../views/passPage/UserAgreement.vue'
import GPrivacyPolicy from '../views/jjgg/PrivacyPolicy.vue'
import GUserAgreement from '../views/jjgg/UserAgreement.vue'
import GDownPage from '../views/jjgg/DownPage.vue'
import HUserAgreement from '../views/hitrun/UserAgreement.vue'
import HPrivacyPolicy from '../views/hitrun/PrivacyPolicy.vue'
import DownPage from '../views/DownPage.vue'
import IosPage from '../views/IosPage.vue'
import ShareUserAgreement from '../views/ShareUserAgreement.vue'
import ShareUserRule from '../views/ShareUserRule.vue'
import ShareUserAgreements from '../views/ShareUserAgreements.vue'
import ShareUserRules from '../views/ShareUserRules.vue'
import PrivacyPolicys from '../views/passPage/PrivacyPolicys.vue'
import HmPrivacyPolicy from '../views/passPage/HmPrivacyPolicy.vue'
import UserAgreements from '../views/passPage/UserAgreements.vue'
import InviteHome from '../views/invite/InviteHome.vue'
import InviteBase from '../views/invite/InviteBase.vue'
import CashPage from '../views/invite/CashPage.vue'
import CashRecord from '../views/invite/CashRecord.vue'
import InviteRecord from '../views/invite/InviteRecord.vue'
import PunishPage from '../views/banner/PunishPage.vue'
import StandardPage from '../views/banner/StandardPage.vue'
import GuidePage from '../views/passPage/GuidePage.vue'
import VipAgreement from '../views/passPage/VipAgreement.vue'
import VipAgreements from '../views/passPage/VipAgreements.vue'
import SdkInfo from '../views/passPage/SdkInfo.vue'
import SdkInfos from '../views/passPage/SdkInfos.vue'
import HmSdkInfo from '../views/passPage/HmSdkInfo.vue'
import CollectHome from '../views/collect/CollectHome.vue'
import CollectBase from '../views/collect/CollectBase.vue'
import CollectUserInfo from '../views/collect/CollectUserInfo.vue'
import UserName from '../views/collect/user/UserName.vue'
import UserAge from '../views/collect/user/UserAge.vue'
import UserSex from '../views/collect/user/UserSex.vue'
import UserPhone from '../views/collect/user/UserPhone.vue'
import UserZfb from '../views/collect/user/UserZfb.vue'
import UserJob from '../views/collect/user/UserJob.vue'
import UserHeight from '../views/collect/user/UserHeight.vue'
import UserWeight from '../views/collect/user/UserWeight.vue'
import UserSign from '../views/collect/user/UserSign.vue'
import UserDesc from '../views/collect/user/UserDesc.vue'
import CollectUserCert from '../views/collect/CollectUserCert.vue'
import UserCert from '../views/collect/user/UserCert.vue'
import CollectUserFace from '../views/collect/CollectUserFace.vue'
import UserFace from '../views/collect/user/UserFace.vue'
import CollectUserAuth from '../views/collect/CollectUserAuth.vue'
import UserAvatar from '../views/collect/user/UserAvatar.vue'
import UserNickname from '../views/collect/user/UserNickname.vue'
import CollectUserPay from '../views/collect/CollectUserPay.vue'
import UserOrder from '../views/collect/user/UserOrder.vue'
import CollectUserLocation from '../views/collect/CollectUserLocation.vue'
import UserLocation from '../views/collect/user/UserLocation.vue'
import CollectUserDevice from '../views/collect/CollectUserDevice.vue'
import DeviceNumber from '../views/collect/device/DeviceNumber.vue'
import DeviceSystem from '../views/collect/device/DeviceSystem.vue'
import DeviceConfig from '../views/collect/device/DeviceConfig.vue'
import DeviceUnique from '../views/collect/device/DeviceUnique.vue'
import DeviceCode from '../views/collect/device/DeviceCode.vue'
import DeviceAddress from '../views/collect/device/DeviceAddress.vue'
import DeviceIdfv from '../views/collect/device/DeviceIdfv.vue'
import DeviceIdfa from '../views/collect/device/DeviceIdfa.vue'
import DeviceOnei from '../views/collect/device/DeviceOnei.vue'
import DeviceUuid from '../views/collect/device/DeviceUuid.vue'
import DeviceOaid from '../views/collect/device/DeviceOaid.vue'
import DeviceAndroid from '../views/collect/device/DeviceAndroid.vue'
import DeviceImei from '../views/collect/device/DeviceImei.vue'
import DeviceMac from '../views/collect/device/DeviceMac.vue'
import CollectUserConnect from '../views/collect/CollectUserConnect.vue'
import DeviceOperator from '../views/collect/device/DeviceOperator.vue'
import DeviceIccid from '../views/collect/device/DeviceIccid.vue'
import DeviceBssid from '../views/collect/device/DeviceBssid.vue'
import DeviceSsid from '../views/collect/device/DeviceSsid.vue'
import PreventionGuide from '../views/passPage/PreventionGuide.vue'
import NetNotice from '../views/passPage/NetNotice.vue'
import IosPayAgreement from '../views/passPage/IosPayAgreement.vue'
import RechargeAgreement from '../views/passPage/RechargeAgreement.vue'

const routes = [
  {
    path: '/',
    component: HomeView,
    children: [
    ]
  },
  {
    path: '/down-page/:code?',
    name: 'DownPage',
    component: DownPage,
    meta: {
      title: '小圈'
    }
  },
  {
    path: '/ios/:code?',
    name: 'IosPage',
    component: IosPage,
    meta: {
      title: '小圈约'
    }
  },
  {
    path: '/share-user-agreement',
    name: 'ShareUserAgreement',
    component: ShareUserAgreement,
    meta: {
      title: '小圈'
    }
  },
  {
    path: '/share-user-rule',
    name: 'ShareUserRule',
    component: ShareUserRule,
    meta: {
      title: '小圈'
    }
  },
  {
    path: '/share-user-agreements',
    name: 'ShareUserAgreements',
    component: ShareUserAgreements,
    meta: {
      title: '小圈约'
    }
  },
  {
    path: '/share-user-rules',
    name: 'ShareUserRules',
    component: ShareUserRules,
    meta: {
      title: '小圈约'
    }
  },
  {
    path: '/sdk-info',
    name: 'SdkInfo',
    component: SdkInfo,
    meta: {
      title: '小圈'
    }
  },
  {
    path: '/sdk-infos',
    name: 'SdkInfos',
    component: SdkInfos,
    meta: {
      title: '小圈约'
    }
  },
  {
    path: '/hm-sdk-info',
    name: 'HmSdkInfo',
    component: HmSdkInfo,
    meta: {
      title: '小圈约'
    }
  },
  {
    path: '/punish-page',
    name: 'PunishPage',
    component: PunishPage,
    meta: {
      title: '小圈'
    }
  },
  {
    path: '/standard-page',
    name: 'StandardPage',
    component: StandardPage,
    meta: {
      title: '小圈'
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/hm-privacy-policy',
    name: 'HmPrivacyPolicy',
    component: HmPrivacyPolicy
  },
  {
    path: '/user-agreement',
    name: 'UserAgreement',
    component: UserAgreement
  },
  {
    path: '/recharge-agreement',
    name: 'RechargeAgreement',
    component: RechargeAgreement
  },
  {
    path: '/ios-pay-agreement',
    name: 'IosPayAgreement',
    component: IosPayAgreement
  },
  {
    path: '/privacy-policys',
    name: 'PrivacyPolicys',
    component: PrivacyPolicys
  },
  {
    path: '/prevention-guide',
    name: 'PreventionGuide',
    component: PreventionGuide
  },
  {
    path: '/net-notice/:id?',
    name: 'NetNotice',
    component: NetNotice
  },
  {
    path: '/info-collect',
    component: CollectBase,
    children: [
      {
        path: '',
        name: 'CollectHome',
        component: CollectHome
      },
      {
        path: 'user-info',
        children: [
          {
            path: '',
            name: 'CollectUserInfo',
            component: CollectUserInfo
          },
          {
            path: 'name',
            name: 'CollectUserName',
            component: UserName
          },
          {
            path: 'age',
            name: 'CollectUserAge',
            component: UserAge
          },
          {
            path: 'sex',
            name: 'CollectUserSex',
            component: UserSex
          },
          {
            path: 'sex',
            name: 'CollectUserPhone',
            component: UserPhone
          },
          {
            path: 'zfb',
            name: 'CollectUserZfb',
            component: UserZfb
          },
          {
            path: 'job',
            name: 'CollectUserJob',
            component: UserJob
          },
          {
            path: 'height',
            name: 'CollectUserHeight',
            component: UserHeight
          },
          {
            path: 'weight',
            name: 'CollectUserWeight',
            component: UserWeight
          },
          {
            path: 'sign',
            name: 'CollectUserSign',
            component: UserSign
          },
          {
            path: 'desc',
            name: 'CollectUserDesc',
            component: UserDesc
          }
        ]
      },
      {
        path: 'user-cert',
        children: [
          {
            path: '',
            name: 'CollectUserCert',
            component: CollectUserCert
          },
          {
            path: 'cert',
            name: 'CollectUserCerts',
            component: UserCert
          }
        ]
      },
      {
        path: 'user-face',
        children: [
          {
            path: '',
            name: 'CollectUserFace',
            component: CollectUserFace
          },
          {
            path: 'face',
            name: 'CollectUserFaces',
            component: UserFace
          }
        ]
      },
      {
        path: 'user-auth',
        children: [
          {
            path: '',
            name: 'CollectUserAuth',
            component: CollectUserAuth
          },
          {
            path: 'avatar',
            name: 'CollectUserAvatar',
            component: UserAvatar
          },
          {
            path: 'nickname',
            name: 'CollectUserNickname',
            component: UserNickname
          }
        ]
      },
      {
        path: 'user-pay',
        children: [
          {
            path: '',
            name: 'CollectUserPay',
            component: CollectUserPay
          },
          {
            path: 'order',
            name: 'CollectUserOrder',
            component: UserOrder
          }
        ]
      },
      {
        path: 'user-location',
        children: [
          {
            path: '',
            name: 'CollectUserLocation',
            component: CollectUserLocation
          },
          {
            path: 'location',
            name: 'CollectUserIndex',
            component: UserLocation
          }
        ]
      },
      {
        path: 'user-device',
        children: [
          {
            path: '',
            name: 'CollectUserDevice',
            component: CollectUserDevice
          },
          {
            path: 'number',
            name: 'CollectDeviceNumber',
            component: DeviceNumber
          },
          {
            path: 'system',
            name: 'CollectDeviceSystem',
            component: DeviceSystem
          },
          {
            path: 'config',
            name: 'CollectDeviceConfig',
            component: DeviceConfig
          },
          {
            path: 'unique',
            name: 'CollectDeviceUnique',
            component: DeviceUnique
          },
          {
            path: 'code',
            name: 'CollectDeviceCode',
            component: DeviceCode
          },
          {
            path: 'address',
            name: 'CollectDeviceAddress',
            component: DeviceAddress
          },
          {
            path: 'idfv',
            name: 'CollectDeviceIdfv',
            component: DeviceIdfv
          },
          {
            path: 'idfa',
            name: 'CollectDeviceIdfa',
            component: DeviceIdfa
          },
          {
            path: 'onei',
            name: 'CollectDeviceOnei',
            component: DeviceOnei
          },
          {
            path: 'uuid',
            name: 'CollectDeviceUuid',
            component: DeviceUuid
          },
          {
            path: 'oaid',
            name: 'CollectDeviceOaid',
            component: DeviceOaid
          },
          {
            path: 'android',
            name: 'CollectDeviceAndroid',
            component: DeviceAndroid
          },
          {
            path: 'imei',
            name: 'CollectDeviceImei',
            component: DeviceImei
          },
          {
            path: 'mac',
            name: 'CollectDeviceMac',
            component: DeviceMac
          },
          {
            path: 'iccid',
            name: 'CollectDeviceIccid',
            component: DeviceIccid
          },
          {
            path: 'bssid',
            name: 'CollectDeviceBssid',
            component: DeviceBssid
          },
          {
            path: 'ssid',
            name: 'CollectDeviceSsid',
            component: DeviceSsid
          }
        ]
      },
      {
        path: 'user-connect',
        children: [
          {
            path: '',
            name: 'CollectUserConnect',
            component: CollectUserConnect
          },
          {
            path: 'operator',
            name: 'CollectDeviceOperator',
            component: DeviceOperator
          }
        ]
      }
    ]
  },
  {
    path: '/user-agreements',
    name: 'UserAgreements',
    component: UserAgreements
  },
  {
    path: '/jjgg/privacy-policy',
    name: 'GPrivacyPolicy',
    component: GPrivacyPolicy
  },
  {
    path: '/jjgg/user-agreement',
    name: 'GUserAgreement',
    component: GUserAgreement
  },
  {
    path: '/download/jjgg',
    name: 'GDownPage',
    component: GDownPage
  },
  {
    path: '/hitrun/privacy-policy',
    name: 'HPrivacyPolicy',
    component: HPrivacyPolicy
  },
  {
    path: '/hitrun/user-agreement',
    name: 'HUserAgreement',
    component: HUserAgreement
  },
  {
    path: '/vip-agreement',
    name: 'VipAgreement',
    component: VipAgreement
  },
  {
    path: '/vip-agreements',
    name: 'VipAgreements',
    component: VipAgreements
  },
  {
    path: '/invite-home',
    component: InviteBase,
    children: [
      {
        path: '',
        name: 'InviteHome',
        component: InviteHome
      },
      {
        path: '/cash-page',
        name: 'CashPage',
        component: CashPage
      },
      {
        path: '/cash-record',
        name: 'CashRecord',
        component: CashRecord
      },
      {
        path: '/invite-record',
        name: 'InviteRecord',
        component: InviteRecord
      },
      {
        path: '/guide-page/:id?',
        name: 'GuidePage',
        component: GuidePage
      }
    ]
  },
  {
    path: '/app',
    name: 'AppLink',
    component: () => import('../views/AppLink')
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// router.beforeEach(() => {
//   document.body.scrollTop = 0
//   document.documentElement.scrollTop = 0
//   window.pageXOffset = 0
// })
router.afterEach(() => {
  window.scrollTo(0, 0)
})
export default router
