import store from '@/store';
import { nextTick } from 'vue'; // @ts-ignore
// import VConsole from 'vconsole/dist/vconsole.min'
// const vConsole = new VConsole()

export default {
  name: 'InviteBase',

  setup() {
    function setupWKWebViewJavascriptBridge(callback) {
      if (window.WKWebViewJavascriptBridge) {
        return callback(window.WKWebViewJavascriptBridge);
      }

      if (window.WKWVJBCallbacks) {
        return window.WKWVJBCallbacks.push(callback);
      }

      window.WKWVJBCallbacks = [callback];
      window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(null);
    }

    const param = {
      method: 'getUserInfo',
      params: {},
      callback: 'onReceiveUser'
    };
    document.getElementById('viewport').content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0;';
    nextTick(() => {
      if (window.HybridAPI !== undefined) {
        window.HybridAPI.onReceiveUser = msg => {
          const data = JSON.parse(msg);
          store.dispatch('SaveToken', data.token).then(() => {
            store.dispatch('RefreshInv');
          });
        };

        window.HybridAPI.sendToNative(JSON.stringify(param));
      }

      setupWKWebViewJavascriptBridge(function (bridge) {
        bridge.registerHandler('onReceiveUser', function (msg) {
          const data = JSON.parse(msg);
          store.dispatch('SaveToken', data.token).then(() => {
            store.dispatch('RefreshInv');
          });
        });
        bridge.callHandler('testiOSCallback', param, function () {});
      });
    });
    return {// vConsole
    };
  }

};