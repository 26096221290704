import BackHead from '@/assets/invite/back_head.png';
import Back from '@/assets/invite/back.png';
import Word from '@/assets/invite/word.png';
import TipsTop from '@/assets/invite/tips-top.png';
import LinkQuan from '@/assets/invite/link_quan.png';
import FriendQuan from '@/assets/invite/friend_quan.png';
import PriceQuan from '@/assets/invite/price_quan.png';
import More from '@/assets/invite/more.png';
import InvBack from '@/assets/invite/inv_back.png';
import LineLeft from '@/assets/invite/line-left.png';
import LineRight from '@/assets/invite/line-right.png';
import Close from '@/assets/invite/close.png';
import MidImg from '@/assets/invite/mid-img.png'; // import { topInvite } from '@/api/user'

import { computed, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import store from '@/store';
export default {
  name: 'InviteHome',

  setup() {
    const show = ref(false);
    const yue = ref(false);
    const router = useRouter();
    const route = useRoute();

    if (route.query.channel !== undefined && route.query.channel !== null && route.query.channel === 'huawei') {
      yue.value = true;
    } // const token = computed(() => {
    //   return store.getters.getToken
    // })


    function setupWKWebViewJavascriptBridge(callback) {
      if (window.WKWebViewJavascriptBridge) {
        return callback(window.WKWebViewJavascriptBridge);
      }

      if (window.WKWVJBCallbacks) {
        return window.WKWVJBCallbacks.push(callback);
      }

      window.WKWVJBCallbacks = [callback];
      window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(null);
    }

    const info = computed(() => {
      return store.getters.invInfo;
    });

    const back = () => {
      const param = {
        method: 'closeWindow',
        params: {
          url: info.value.url
        },
        callback: ''
      };

      if (window.HybridAPI !== undefined) {
        window.HybridAPI.sendToNative(JSON.stringify(param));
      } else {
        router.back();
      }

      setupWKWebViewJavascriptBridge(function (bridge) {
        bridge.callHandler('testiOSCallback', param, function () {});
      });
    };

    const showTips = () => {
      show.value = true;
    };

    const closeTip = () => {
      show.value = false;
    };

    const link = () => {
      const param = {
        method: 'shareLink',
        params: {
          url: info.value.url
        },
        callback: ''
      };

      if (window.HybridAPI !== undefined) {
        window.HybridAPI.sendToNative(JSON.stringify(param));
      }

      setupWKWebViewJavascriptBridge(function (bridge) {
        bridge.callHandler('testiOSCallback', param, function () {});
      });
    };

    const haibao = () => {
      const param = {
        method: 'shareHaibao',
        params: {
          url: info.value.url
        },
        callback: ''
      };

      if (window.HybridAPI !== undefined) {
        window.HybridAPI.sendToNative(JSON.stringify(param));
      }

      setupWKWebViewJavascriptBridge(function (bridge) {
        bridge.callHandler('testiOSCallback', param, function () {});
      });
    };

    const list = ref([]); // if (window.HybridAPI !== undefined) {
    //   if (token.value !== '') {
    //     topInvite().then(res => {
    //       if (res.code === 0) {
    //         list.value = res.data
    //       }
    //     })
    //   } else {
    //     const inter = setInterval(() => {
    //       if (token.value !== '') {
    //         topInvite().then(res => {
    //           if (res.code === 0) {
    //             list.value = res.data
    //           }
    //         }).finally(() => clearInterval(inter))
    //       } else {
    //         clearInterval(inter)
    //       }
    //     }, 2000)
    //   }
    // }

    return {
      BackHead,
      Back,
      back,
      showTips,
      LinkQuan,
      FriendQuan,
      PriceQuan,
      More,
      InvBack,
      list,
      link,
      haibao,
      Close,
      closeTip,
      show,
      router,
      info,
      yue,
      TipsTop,
      LineLeft,
      LineRight,
      Word,
      MidImg
    };
  }

};